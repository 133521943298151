import { generateRandomId } from '@starsoft/common/utils';
import { TagManagerEventParams } from './props';
import { TagManagerEventType } from '@/models/user-tag-manager-events/type';

export abstract class GTMService {
  static sendEvent<T extends TagManagerEventType>(
    event: T,
    payload: Omit<TagManagerEventParams, 'event'>,
  ) {
    if (typeof window == 'undefined') {
      return;
    }

    if ('dataLayer' in window) {
      window.dataLayer.push({
        event,
        event_id:
          'crypto' in window ? window.crypto.randomUUID() : generateRandomId(),
        ...payload,
      });
    }
  }
}
