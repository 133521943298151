import { Coin, CoinType, CurrencyOptions } from '@starsoft/common/models';
import { CreateMoneyPayload } from './props';
import { Either, Nullable } from '@starsoft/common/interfaces';
import { usdFormatting } from '@starsoft/common/constants';

export class Money {
  private readonly units: number;
  private readonly locale: Either<string, undefined>;
  private readonly symbol: Either<string, undefined>;
  private readonly code: Either<string, undefined>;
  private readonly type: CoinType;
  private readonly scaleFactor: number;
  private readonly highScaleFactor: number;
  private _amount: number;

  constructor(createMoneyPayload: CreateMoneyPayload) {
    const coin: Coin | CurrencyOptions =
      createMoneyPayload?.coin ?? usdFormatting;
    this.code = coin.code;
    this.symbol = coin.symbol;
    this.locale = coin.locale;
    this.type = coin.type ?? CoinType.Fiat;
    this.units = coin.decimals;

    this.scaleFactor = Math.pow(10, this.units);
    this.highScaleFactor = Math.pow(10, this.units * 2);

    this._amount = this.fromSubunits(
      this.toSubunits(createMoneyPayload.amount ?? 0),
    );
  }

  get amount(): number {
    return this._amount;
  }

  set amount(value: number) {
    this._amount = this.fromSubunits(this.toSubunits(value));
  }

  get amountInSmallestUnit(): bigint {
    return this.toSubunits(this._amount);
  }

  get maskedAmount(): Nullable<string> {
    if (!this?.locale) {
      return null;
    }

    if (this.type === CoinType.Fiat) {
      return new Intl.NumberFormat(this.locale, {
        style: 'currency',
        currency: this.code,
        currencyDisplay: 'symbol',
        minimumFractionDigits: this.units,
        maximumFractionDigits: this.units,
      })
        .format(this._amount)
        .replace(/\s/g, '');
    }

    return (
      new Intl.NumberFormat(this.locale, {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: this.units,
      }).format(this._amount) + ` ${this.symbol}`
    );
  }

  get formattedAmount(): Nullable<string> {
    if (!this?.locale) {
      return null;
    }

    const fixedAmount = this._amount.toFixed(this.units);
    if (this.type === CoinType.Fiat) {
      return new Intl.NumberFormat(this.locale, {
        style: 'currency',
        currency: this.code,
        currencyDisplay: 'code',
        minimumFractionDigits: this.units,
        maximumFractionDigits: this.units,
      })
        .format(Number(fixedAmount))
        .replace(this.code ?? '', '')
        .replace(/\s/g, '');
    }

    return new Intl.NumberFormat(this.locale, {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: this.units,
    }).format(Number(fixedAmount));
  }

  public add(value: number): void {
    this._amount = this.fromSubunits(
      this.amountInSmallestUnit + this.toSubunits(value),
    );
  }

  public sub(value: number): void {
    this._amount = this.fromSubunits(
      this.amountInSmallestUnit - this.toSubunits(value),
    );
  }

  public multiply(value: number, multiplier: number): number {
    const scaledMultiplier: bigint = BigInt(
      Math.floor(multiplier * (this.highScaleFactor ?? 1)),
    );
    const amountInSubUnits: bigint = this.toSubunits(value);

    return this.fromSubunits(
      (amountInSubUnits * scaledMultiplier) / BigInt(this.highScaleFactor ?? 1),
    );
  }

  public divide(value: number, divisor: number): number {
    const scaledDivisor: bigint = BigInt(
      Math.floor(divisor * (this.scaleFactor ?? 1)),
    );
    const amountInSubUnits: bigint = this.toSubunits(value);

    return this.fromSubunits(
      (amountInSubUnits * BigInt(this.scaleFactor)) / scaledDivisor,
    );
  }

  public toSubunits(value: number): bigint {
    return BigInt(Math.floor((isNaN(value) ? 0 : value) * this.scaleFactor));
  }

  public fromSubunits(value: bigint | number | string): number {
    return (
      Number(typeof value === 'bigint' ? value : BigInt(value ?? 0)) /
      (this.scaleFactor ?? 1)
    );
  }
}
