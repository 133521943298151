import { AnimationItem, LottiePlayer } from 'lottie-web';
import { useEffect, useRef, useState } from 'react';
import { UseLottiePlayerProps, UseLottiePlayerReturn } from './props';
import { SettingsService } from '@starsoft/common/services/SettingsService';

export function useLottiePlayer<T>({
  path,
  autoplay = true,
  loop = true,
  dependecyArray = [],
  initialSegment,
}: UseLottiePlayerProps<T>): UseLottiePlayerReturn {
  const [lottie, setLottie] = useState<LottiePlayer | null>(null);
  const lottieAnimationRef = useRef(null);

  const loadLottiePlayer = () => {
    import('lottie-web').then(Lottie => setLottie(Lottie.default));
  };

  useEffect(loadLottiePlayer, []);

  const startLottieAnimation = () => {
    if (lottie && lottieAnimationRef.current) {
      const animation: AnimationItem = lottie.loadAnimation({
        container: lottieAnimationRef!.current,
        renderer: 'svg',
        loop,
        autoplay,
        path: `${SettingsService.env.S3_BUCKET_BASE_URL}${SettingsService.env.LOTTIE_URL ?? '/lotties'}${path?.startsWith('/') ? path : `/${path}`}`,
        initialSegment,
      });

      return () => {
        animation.destroy();
      };
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(startLottieAnimation, [
    lottie,
    autoplay,
    loop,
    path,
    //eslint-disable-next-line
    ...dependecyArray,
  ]);

  return { lottieAnimationRef, lottie };
}

export type { UseLottiePlayerProps, UseLottiePlayerReturn };
