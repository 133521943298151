import { BalanceModalContentProps } from './props';
import { useMemo, useState } from 'react';
import styles from './styles.module.scss';
import BalanceModalContentAccounts from './Accounts';
import BalanceModalSearchInput from './SearchInput';
import useTranslation from 'next-translate/useTranslation';
import { Account, CoinType } from '@starsoft/common/models';

export default function BalanceModalContent({
  error,
  refetch,
  onClick,
  accounts,
  isLoading,
  isLoadingAction,
  isContainerColor,
  disabledCurrent,
}: BalanceModalContentProps) {
  const [search, setSearch] = useState<string>('');
  const { t } = useTranslation('common');

  const { fiat, crypto } = useMemo(() => {
    const fiat: Account[] = [];
    const crypto: Account[] = [];

    accounts.forEach(account => {
      switch (account.coin.type) {
        case CoinType.Fiat:
          fiat.push(account);
          break;
        case CoinType.Crypto:
          crypto.push(account);
          break;
      }
    });

    return { fiat, crypto };
  }, [accounts]);

  return (
    <div className={styles.container}>
      {!error && (
        <BalanceModalSearchInput
          search={search}
          isLoading={isLoading}
          setSearch={setSearch}
          isContainerColor={isContainerColor}
        />
      )}
      <ul className={styles.container__list}>
        {!error && fiat?.length > 0 && (
          <span className={styles.container__list__label}>
            {t('balance_modal_label_divider_fiat')}
          </span>
        )}

        <BalanceModalContentAccounts
          accounts={fiat}
          error={error}
          onClick={onClick}
          refetch={refetch}
          search={search}
          isLoading={isLoading}
          isLoadingAction={isLoadingAction}
          disabledCurrent={disabledCurrent}
        />

        {!error && crypto?.length > 0 && (
          <>
            <span className={styles.container__list__label}>
              {t('balance_modal_label_divider_crypto')}
            </span>
            <BalanceModalContentAccounts
              accounts={crypto}
              onClick={onClick}
              search={search}
              isLoading={isLoading}
              isLoadingAction={isLoadingAction}
              disabledCurrent={disabledCurrent}
              error={error}
              refetch={refetch}
            />
          </>
        )}
      </ul>
    </div>
  );
}
